export const Configurations=[
    {
        assets: "#01C2FF",
        url: "https://barknbubblesleesburg.com/",
        Id: "PCB00108350",
        icon: "BarknBubbles",
        title: "Dog Groomer in Leesburg Virginia | Bark N' Bubbles of Leesburg",
        description: "Bark N’ Bubbles is a professional dog grooming service in Leesburg, VA. We offer by-appointment and full-service grooming options for your furry friends."
    },
    {
        assets: "#01C2FF",
        url: "https://www.barknbubblesleesburg.com/",
        Id: "PCB00108350",
        icon: "BarknBubbles",
        title: "Dog Groomer in Leesburg Virginia | Bark N' Bubbles of Leesburg",
        description: "Bark N’ Bubbles is a professional dog grooming service in Leesburg, VA. We offer by-appointment and full-service grooming options for your furry friends."
    }
]
